import dayjs from 'dayjs';

export const FORM_SEARCH_TABLE = () => [
  {
    ref: 'customer',
    name: 'customer',
    label: 'เลขบัตรประชาชน / ชื่อ-นามสกุล ลูกค้า',
    type: 'INPUT',
    span: 6,
    labelStyle: {
      fontSize: '14px',
      fontFamily: 'SarabunBold',
    },
  },
  {
    ref: 'date',
    name: 'date',
    label: 'เงื่อนไขวันที่',
    type: 'DATE_RANGE',
    span: 6,
    defaultValue: [dayjs(), dayjs()],
    labelStyle: {
      fontSize: '14px',
    },
  },
];
