import React from 'react';
import { Col, Row } from 'antd';
import { MemoizedDropdown } from './dropdown';
import { MemoizedInputLabel, MemoizedInputPassword, InputNumber } from './input';
import { Fragment } from 'preact';
import { MemoizedUpload } from '../forms/upload';
import { MemoizedRadio } from '../forms/radio';
import { MemoizedCheckbox } from './checkbox';
import { MemoizedSwitch } from './switch';
import { MemoizedDatePicker, MemoizedDateRange } from './datepicker';
import { TextLabel } from './text';
import moment from 'moment';
import { MemoizedTimePicker } from './timepicker';
export * from './input';
export * from './dropdown';

/**
 * React hook forms --> https://react-hook-form.com/get-started
 */

export const RenderForm = ({
  forms = [],
  spaceBottom = true,
  setValue,
  getValues,
  control,
  styleRow,
  onChange,
  onBlur,
  onClear,
}) => {
  function handleValue(e, item) {
    if (!e && item.type !== 'SWITCH') {
      return;
    }

    switch (item.type) {
      case 'DATE_PICKER':
        return moment(new Date(e)).format(item.format || 'DD/MM/YYYY');
      case 'DATE_RANGE':
        return e.map((date) => moment(new Date(date)).format(item.format || 'DD/MM/YYYY'));
      case 'TIME_PICKER':
        return moment(new Date(e)).format('HH:mm');
      case 'DROPDOWN':
      case 'SELECT':
      case 'SELECT_MULTI':
      case 'SWITCH':
      case 'CHECKBOX':
      case 'SELECT_MODAL':
        return e;
      case 'FILE_UPLOAD':
      case 'IMAGE_UPLOAD':
        return e.fileList;
      default:
        if (!e.target) {
          return e;
        }

        return e.target.value;
    }
  }

  function handleChange(e, item, option) {
    const val = handleValue(e, item);
    setValue(item.name, val);
    onChange && onChange(val, item, option);
  }

  function handleClear(e, item) {
    setValue(item.name, '');
    onClear && onClear(e, item);
  }

  const rest = {
    control,
    setValue,
    getValues,
    handleChange,
    handleBlur: (e, item, option) => {
      const val = handleValue(e, item);
      handleChange(e, item, option);
      onBlur && onBlur(val, item, option);
    },
    handleClear,
  };

  return (
    <Fragment>
      <Row gutter={[8, 8]} style={styleRow}>
        {forms.map((f, i) => {
          return (
            <Col
              key={`colForm${i}`}
              xs={{ span: 24 }}
              md={{ span: 24 }}
              xl={{ span: f.span }}
              lg={{ span: f.span }}
              style={{ ...f.style }}
            >
              {renderInputType(f, rest)}
            </Col>
          );
        })}
      </Row>
      {spaceBottom && <div style={{ marginBottom: '16px' }} />}
    </Fragment>
  );
};

export const renderTypeError = (item, error) => {
  if (error && error.message) {
    return error.message;
  }
  switch (error.type) {
    case 'required':
      return `โปรดระบุ`;
    case 'pattern':
      return `รูปแบบไม่ถูกต้อง`;
    case 'maxLength':
      return `ระบุไม่เกิน ${error.message} ตัวอักษร`;
    case 'max':
      return `ระบุจำนวนไม่เกิน ${error.message} ตัวอักษร`;
    case 'minLength':
      return `ระบุไม่น้อยกว่า ${error.message} ตัวอักษร`;
    case 'min':
      return `ระบุไม่น้อยกว่า ${error.message} ตัวอักษร`;
  }
};

export function renderInputType(item, globalProps) {
  const { type } = item;
  switch (type) {
    case 'TEXT':
      return <TextLabel item={{ ...item }} {...globalProps} />;
    case 'INPUT':
      return <MemoizedInputLabel item={{ ...item }} {...globalProps} />;
    case 'INPUT_PASSWORD':
      return <MemoizedInputPassword item={{ ...item }} {...globalProps} />;
    case 'INPUT_NUMBER':
      return <InputNumber item={{ ...item }} {...globalProps} />;
    case 'DROPDOWN':
      return <MemoizedDropdown item={item} {...globalProps} />;
    case 'UPLOAD':
      return <MemoizedUpload item={item} {...globalProps} />;
    case 'RADIO':
      return <MemoizedRadio item={item} {...globalProps} />;
    case 'CHECKBOX':
      return <MemoizedCheckbox item={item} {...globalProps} />;
    case 'SWITCH':
      return <MemoizedSwitch item={item} {...globalProps} />;
    case 'DATE_PICKER':
      return <MemoizedDatePicker item={item} {...globalProps} />;
    case 'DATE_RANGE':
      return <MemoizedDateRange item={item} {...globalProps} />;
    case 'TIME_PICKER':
      return <MemoizedTimePicker item={item} {...globalProps} />;
    default:
      return <div />;
  }
}
