import './style/table.css';
import { StyledContainer } from '../../components/container/styled';
import { PageLayout } from '../../components/page-layout/PageLayout';
import { Icon } from '../../resources';
import { COLUMNS } from './constant/table';
import { FORM_SEARCH_TABLE } from './form/search.table';
import useGetReportIntst from './hook/useGetReportIntst';
import { TableExpand } from './view/table';

const ReportIntstScene = () => {
  const { dataIntst, totalDataIntst, onSearch, isLoadingTable } = useGetReportIntst();

  function onDelete(record) {
    console.log(record);
  }

  return (
    <StyledContainer>
      <PageLayout
        searchLayout={{
          title: 'จัดการชำระดอกเบี้ย',
          formSearch: FORM_SEARCH_TABLE(),
          icon: <Icon.todo />,
          onSearch: (data) => onSearch(data),
        }}
        tableLayout={{
          bordered: true,
          tableLayout: 'fixed',
          loading: isLoadingTable,
          columns: COLUMNS({ onDelete }),
          rowKey: 'indx',
          dataSource: dataIntst,
          total: totalDataIntst,
          hasPagination: true,
          scroll: { x: 'max-content' },
          tableStyle: { marginTop: '5px' },
          expandable: {
            expandedRowRender: (record) => <TableExpand record={record} onDelete={onDelete} />,
            rowExpandable: (record) => record.name !== 'Not Expandable',
          },
        }}
      />
    </StyledContainer>
  );
};

export default ReportIntstScene;
