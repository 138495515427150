import React from 'react';
import {
  BsFillCloudArrowDownFill,
  BsSearch,
  BsFillPenFill,
  BsFillTrashFill,
  BsBookHalf,
  BsFillXCircleFill,
  BsSave2Fill,
  BsPrinterFill,
  BsFillCheckCircleFill,
  BsLockFill,
  BsArrowLeftCircleFill,
  BsFillPlusCircleFill,
  BsArrowClockwise,
  BsShieldFillCheck,
  BsPersonCheckFill,
  BsKanban,
  BsJournalBookmarkFill,
  BsLayoutSidebarInsetReverse,
  BsImages,
  BsUpload,
} from 'react-icons/bs';
import { RiLogoutBoxRFill, RiLoginBoxFill } from 'react-icons/ri';
import { IoSettingsOutline, IoBookmarks, IoAlertCircleSharp, IoCheckbox } from 'react-icons/io5';
import { HiDotsCircleHorizontal } from 'react-icons/hi';
import { FaChevronCircleDown, FaExternalLinkAlt } from 'react-icons/fa';
import { MdWavingHand } from 'react-icons/md';
import { TbReport } from 'react-icons/tb';
/**
 * React Icons --> https://react-icons.github.io/react-icons/
 */
export const Icon = {
  download: () => <BsFillCloudArrowDownFill />,
  search: () => <BsSearch />,
  edit: () => <BsFillPenFill />,
  remove: () => <BsFillTrashFill />,
  view: (props) => <BsBookHalf {...props} />,
  clear: () => <BsFillXCircleFill />,
  cancel: () => <BsFillXCircleFill />,
  logout: () => <RiLogoutBoxRFill />,
  login: () => <RiLoginBoxFill />,
  save: () => <BsSave2Fill />,
  print: () => <BsPrinterFill />,
  confirm: () => <BsFillCheckCircleFill />,
  forgetPassword: () => <BsLockFill />,
  back: () => <BsArrowLeftCircleFill />,
  create: () => <BsFillPlusCircleFill />,
  reAct: () => <BsArrowClockwise />,
  recheck: () => <BsShieldFillCheck />,
  register: () => <BsPersonCheckFill />,
  settings: (props) => <IoSettingsOutline {...props} />,
  threeDots: (props) => <HiDotsCircleHorizontal {...props} />,
  downCircle: (props) => <FaChevronCircleDown {...props} />,
  bookmark: (props) => <IoBookmarks {...props} />,
  alert: (props) => <IoAlertCircleSharp {...props} />,
  checkboxSquare: (props) => <IoCheckbox {...props} />,
  wavingHand: (props) => <MdWavingHand {...props} />,
  external: (props) => <FaExternalLinkAlt {...props} />,
  /** MENU ICON */
  dashboard: () => <BsKanban />,
  todo: () => <BsJournalBookmarkFill />,
  formExample: () => <BsLayoutSidebarInsetReverse />,
  history: () => <TbReport />,
  import: () => <BsUpload />,
  image: (props) => <BsImages {...props} />,
};
