import React, { Fragment } from 'preact';
import { useController } from 'react-hook-form';
import { Required, RowComponent } from '../../../styles/global-style';
import { TextXSMall, TextInputLabel } from '../../text';
import { useEffect } from 'preact/hooks';
import { Switch } from 'antd';
import { renderTypeError } from '..';

const SwitchComponent = ({ control, item, handleChange, setValue, getValues }) => {
  const { rules, name, defaultValue, label, disabled, propperties } = item;

  const { fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;

  useEffect(() => {
    setValue(name, item.defaultValue || false);
  }, []);

  return (
    <Fragment>
      <RowComponent>
        {label && <TextInputLabel text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <Switch
        id={name}
        disabled={disabled}
        {...propperties}
        defaultChecked={item.defaultValue || false}
        onChange={(e) => handleChange(e, item)}
      />
      {error && <TextXSMall text={renderTypeError(item, error)} color="red" />}
    </Fragment>
  );
};

export const MemoizedSwitch = SwitchComponent; //memo(, compareRender);
