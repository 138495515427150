import * as type from './types';

export function login(payload) {
  return {
    type: type.AUTHEN_LOGIN_REQ,
    payload: payload,
  };
}

export function logout(payload) {
  return {
    type: type.AUTHEN_LOGOUT_REQ,
    payload: payload,
  };
}

export function loginGoogle(payload) {
  return {
    type: type.AUTHEN_LOGIN_GOOGLE_REQ,
    payload: payload,
  };
}
