import { createStore, applyMiddleware } from 'redux';
import rootReducer from './rootReducer';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';

/**
 * Redux-saga --> https://redux-saga.js.org/
 * Tutorial with Example --> https://www.blog.duomly.com/implement-redux-saga-with-reactjs-and-redux/
 */

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

export default store;
