import '../style/table.line.css';
import { Fragment } from 'preact';
import TableComponent from '../../../components/table';
import { COLUMNSLINE } from '../constant/table';

export const TableExpand = ({ record, onDelete }) => {
  return (
    <Fragment>
      <div style={{ marginTop: 10 }} />
      <TableComponent
        columns={COLUMNSLINE({ onDelete })}
        dataSource={record.lines}
        hasPagination={false}
        scroll={{ x: 'max-content' }}
        className={'head-column'}
      />
    </Fragment>
  );
};
