import React from 'react';
import IndexScene from '../screens/home/index';
import { Icon } from './icon';
import ReportIntstScene from '../screens/report-intst';
import CustomerScene from '../screens/customer';

export const ROUTE_LOGIN = 'https://central.zetta-system.com';
export const ROUTE_LOGIN_MANUAL = '/account/login';
export const ROUTE_HANDLE = '/account/handle';

export const KEY_ROUTE_MAIN = '@ROUTES/ROUTE_MAIN';
export const KEY_ROUTE_HOME = '@ROUTES/ROUTE_HOME';
export const KEY_ROUTE_DASHBOARD = '@ROUTES/ROUTE_DASHBOARD';
export const KEY_ROUTE_TO_DO_LIST = '@ROUTES/ROUTE_TO_DO_LIST';
export const KEY_ROUTE_EXAMPLE = '@ROUTES/ROUTE_EXAMPLE';
export const KEY_ROUTE_EXAMPLE1 = '@ROUTES/ROUTE_EXAMPLE1';
export const KEY_ROUTE_EXAMPLE_CAROUSEL = '@ROUTES/ROUTE_EXAMPLE_CAROUSEL';
export const KEY_ROUTE_EXAMPLE_BUTTON = '@ROUTES/ROUTE_EXAMPLE_BUTTON';
export const KEY_ROUTE_EXAMPLE_TABLE = '@ROUTES/ROUTE_EXAMPLE_TABLE';
export const KEY_ROUTE_EXAMPLE_NOTI = '@ROUTES/ROUTE_EXAMPLE_NOTI';
export const KEY_ROUTE_EXAMPLE_YUP = '@ROUTES/ROUTE_EXAMPLE_YUP';
export const KEY_ROUTE_EXAMPLE_PAGE = '@ROUTES/ROUTE_EXAMPLE_PAGE';
export const KEY_ROUTE_EXAMPLE_MODAL = '@ROUTES/ROUTE_EXAMPLE_MODAL';

export const KEY_ROUTE_REPORT_INTST = '@ROUTES/ROUTE_REPORT_INTST';
export const KEY_ROUTE_LIST_CUSTOMER = '@ROUTES/ROUTE_LIST_CUSTOMER';

function renderStyle(comp) {
  return <div style={{ marginRight: '1px' }}>{comp}</div>;
}

export const ROUTES_PATH = {
  ROUTE_MAIN: {
    KEY: KEY_ROUTE_MAIN,
    PATH: '/',
    LABEL: 'หน้าหลัก',
    COMPONENT: IndexScene,
  },
  ROUTE_HOME: {
    KEY: KEY_ROUTE_HOME,
    PATH: '/home',
    LABEL: 'หน้าหลัก',
  },
  ROUTE_REPORT_INTST: {
    KEY: KEY_ROUTE_REPORT_INTST,
    ICON: renderStyle(<Icon.dashboard />),
    PATH: '/report/intst',
    LABEL: 'จัดการชำระดอกเบี้ย',
    COMPONENT: ReportIntstScene,
  },
  ROUTE_LIST_CUSTOMER: {
    KEY: KEY_ROUTE_LIST_CUSTOMER,
    ICON: renderStyle(<Icon.register />),
    PATH: '/customer/list',
    LABEL: 'จัดการข้อมูลลูกค้า',
    COMPONENT: CustomerScene,
  },
};

function getKeyLowerCase(obj) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k.toLowerCase(), v]));
}

export const ROUTES_MENU = [
  getKeyLowerCase(ROUTES_PATH.ROUTE_REPORT_INTST),
  getKeyLowerCase(ROUTES_PATH.ROUTE_LIST_CUSTOMER),
];
