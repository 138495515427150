//TODO add to Template
import { Fragment } from 'preact';
import { useController } from 'react-hook-form';
import { RowComponent } from '../../../styles/global-style';
import { TextSmall, TextInputLabel } from '../../text';

export const TextLabel = ({ control, item }) => {
  const { name, defaultValue, label } = item;

  const { field } = useController({
    control,
    name,
    defaultValue,
  });
  const { value } = field;

  return (
    <Fragment>
      <RowComponent>
        {label && <TextInputLabel text={label} key={name} />}
      </RowComponent>
      <TextSmall text={value} style={{ marginLeft: `20px`, color: `#585858` }} />
    </Fragment>
  );
};
