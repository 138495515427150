import React from 'react';
import { Table, Pagination, Typography } from 'antd';
import { useTable } from './hooks/useTable';
import { getStored } from '../../store/useLocalStored';
const { Text } = Typography;

function renderStripe(record, index) {
  if (index % 2 == 0) {
    return getStored('theme') === 'dark' ? 'stripe-row-black' : 'stripe-row-grey';
  }
}

const TableComponent = ({
  columns,
  dataSource,
  withCustomPagination,
  hasPagination,
  getApi,
  rowSelection,
  rowKey,
  components,
  useTableObject = useTable(),
  ...rest
}) => {
  const { tableStyle } = rest;
  const { setCurrentPage, onShowSizeChange, pageSize, defaultPageSize, sizePageOptions, current } =
    useTableObject;

  const onChange = (page, pageSizeNumber) => {
    if (page !== current) {
      setCurrentPage(page);
    }

    if (typeof getApi === 'function') {
      getApi(page, pageSizeNumber);
    }
  };

  const propsTable = {
    onShowSizeChange,
    pageSize: pageSize,
    pageSizeOptions: sizePageOptions,
    showTotal: (t) => `${t} รายการ`,
    defaultPageSize: defaultPageSize,
    defaultCurrent: 1,
  };

  return (
    <>
      <Table
        scroll={{
          y: 300,
        }}
        {...rest}
        components={components}
        rowKey={rowKey || 'key'}
        rowClassName={renderStripe}
        loading={rest.loading}
        rowSelection={
          rowSelection && {
            ...rowSelection,
            selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
          }
        }
        columns={columns}
        dataSource={dataSource}
        pagination={
          hasPagination &&
          !withCustomPagination && {
            total: dataSource.length || 0,
            ...propsTable,
          }
        }
        style={tableStyle}
        summary={() => SummaryTable(rest.summary)}
      />
      {hasPagination && withCustomPagination && (
        <div style={{ textAlign: 'end', margin: '15px 0' }}>
          <Pagination total={rest.total} onChange={onChange} current={current} {...propsTable} />
        </div>
      )}
    </>
  );
};

const SummaryTable = (summary) => {
  if (!summary || JSON.stringify(summary) === '{}') {
    return null;
  }

  const { rowSum, dataSum } = summary;

  return (
    <Table.Summary fixed>
      {rowSum.map((row) => (
        <Table.Summary.Row key={`sumRow_${row.rowNum}`} {...row}>
          {row.columnSum.map((col, i) => (
            <Table.Summary.Cell key={`column_${col.title}`} index={i} colSpan={col.colSpan}>
              <Text style={col.style}>{col.title || (dataSum && dataSum[col.refColumn]) || 0}</Text>
            </Table.Summary.Cell>
          ))}
        </Table.Summary.Row>
      ))}
    </Table.Summary>
  );
};

export default TableComponent;
