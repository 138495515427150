import styled from 'styled-components';
import { Card } from 'antd';

export const CardStyle = styled(Card)`
  border-radius: 10px;

  .ant-card-body {
    padding: 10px;
    padding-right: 16px;
    padding-left: 16px;
  }
`;
