import { App } from 'antd';
import { useSelector } from 'react-redux';
import { closeAlert } from './useAlert';
import { delay } from '../../functions';

const Alert = () => {
  const { message, notification, modal } = App.useApp();
  const alert = useSelector((state) => state.alert);

  const delayRemoveAlert = () => {
    delay(3000);
    closeAlert();
  };

  const renderModel = () => {
    switch (alert.model) {
      case 'notification':
        delayRemoveAlert();
        return notification[alert.type]({ message: alert.message });
      case 'message':
        delayRemoveAlert();
        return message[alert.type]({
          content: alert.message,
          duration: 1,
        });
      case 'modal':
        let content = {
          content: alert.message,
          onCancel: () => {
            closeAlert();
            alert.onFinish();
          },
          onOk: () => {
            closeAlert();
            alert.onFinish();
          },
        };
        if (alert.title) {
          content = { ...content, title: alert.title };
        }
        return modal[alert.type](content);
      default:
        delayRemoveAlert();
        notification.info({ message: '' });
    }
  };
  alert.open && renderModel();
};

export default Alert;
