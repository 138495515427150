import React from 'react';
import { useForm } from 'react-hook-form';
import { ButtonTheme } from './../../components/buttons/index';
import { FORM_INPUT } from './forms/forms';
import { RenderForm } from './../../components/forms/index';
import { ContainerButton } from '../../styles/global-style';
import imgLogin from '../../assets/images/imgLogin.jpg';
import { TextLogoLogin } from '../../components/text';
import { TextLarge } from './../../components/text/index';
import { Icon } from './../../resources/icon';
import { color } from './../../resources/color';
import { useEffect } from 'preact/hooks';
import { useAuthLogin } from '../../hooks/useAuth';

const LoginScene = () => {
  const { control, setValue, getValues, handleSubmit } = useForm({});
  const { redirectToMain, onLogin } = useAuthLogin();

  useEffect(() => {
    redirectToMain();
  }, []);

  const HeaderLogin = (
    <div style={{ width: '100%', textAlignLast: 'center' }}>
      <div style={{ display: 'flex', placeContent: 'center' }}>
        <TextLogoLogin text="Welcome back" bold />
        <div style={{ placeSelf: 'center' }}>
          <Icon.wavingHand style={{ fontSize: '24px', color: color.yellow }} />
        </div>
      </div>
      <TextLarge text="ระบบจัดการตั๋วรับจำนำ" />
    </div>
  );

  return (
    <div style={{ width: '100%' }}>
      <div className="flex-login-box">
        <div style={{ margin: 'auto' }}>
          <div className="login-box">
            <div className="img-login-box">
              <img className="img-login" alt="example" src={imgLogin} />
            </div>
            <div style={{ flex: 1, padding: '2%' }}>
              {HeaderLogin}
              <RenderForm
                control={control}
                forms={FORM_INPUT()}
                setValue={setValue}
                getValues={getValues}
              />
              <ContainerButton center>
                <ButtonTheme useFor="LOGIN" onClick={handleSubmit(onLogin)} />
              </ContainerButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScene;
