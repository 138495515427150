import { TextLarge, TextXSMall } from '../../../components/text';
import { ContainerButton } from '../../../styles/global-style';
import { color } from '../../../resources';
import { Image } from 'antd';
import { TagComponent } from '../../../components/tag';
import { convertStrToFormat } from '../../../functions';

export const COLUMNS = ({ onViewHistory, onDelete }) => {
  return [
    {
      title: '#',
      width: 50,
      dataIndex: 'indx',
      align: 'center',
      fixed: 'left',
      render: (data, _record) => {
        return <TextXSMall text={`${data + 1}`} />;
      },
    },
    {
      title: 'รหัสการชำระ',
      width: 100,
      dataIndex: 'paymentId',
      align: 'center',
      render: (data) => {
        return <TextXSMall text={`${data}`} />;
      },
    },
    {
      title: 'รหัสลูกค้า',
      width: 160,
      dataIndex: 'customerCode',
      align: 'center',
      render: (data) => {
        return <TextXSMall text={`${data}`} />;
      },
      // ...searchFilter('customerCode'),
    },
    {
      title: 'ชื่อ-นามสกุลลูกค้า',
      width: 160,
      dataIndex: 'customerName',
      align: 'center',
      render: (_, record) => {
        return <TextXSMall text={`${record.firstName} ${record.lastName}`} />;
      },
      // ...searchFilter('customerName'),
    },
    {
      title: 'บัตรประชาชน',
      width: 100,
      dataIndex: 'idCard',
      align: 'center',
      render: (data) => {
        return <TextXSMall text={`${data}`} />;
      },
    },
    {
      title: 'เบอร์โทรศัพท์',
      width: 100,
      dataIndex: 'phoneNumber',
      align: 'center',
      render: (data) => {
        return <TextXSMall text={`${data}`} />;
      },
    },
    {
      title: 'วันที่ชำระ',
      width: 120,
      dataIndex: 'recptDate',
      align: 'center',
      render: (data) => {
        return <TextXSMall text={`${convertStrToFormat(data, 'datetime2')}`} />;
      },
    },
    {
      title: 'ยอดชำระ',
      width: 100,
      dataIndex: 'amount',
      align: 'center',
      render: (data) => {
        return <TextLarge text={`${data}`} bold color={color['red']} />;
      },
    },
    {
      title: '',
      dataIndex: 'action',
      skipExport: true,
      width: 80,
      fixed: 'right',
      render: (_, record) => {
        return (
          <ContainerButton center>
            <Image width={50} height={40} src={`${record.uri}`} />
            {/* <Popconfirm
              title={'คุณต้องการลบรายการนี้ใช่หรือไม่'}
              onConfirm={() => onDelete(record.lines.map((e) => e.paymentLineId))}
              okText="ใช่"
              cancelText="ไม่ใช่"
            >
              <ButtonTheme useFor="REMOVE" />
            </Popconfirm> */}
          </ContainerButton>
        );
      },
    },
  ];
};

export const COLUMNSLINE = ({ onDelete }) => {
  return [
    {
      title: 'ตั๋วจำนำวันที่',
      width: 80,
      dataIndex: 'tickStDate',
      align: 'center',
      render: (data) => {
        return <TextXSMall text={`${convertStrToFormat(data, 'date')}`} />;
      },
    },
    {
      title: 'เล่มที่',
      width: 50,
      dataIndex: 'bookNo',
      align: 'center',
      style: { backgroundColor: 'red' },
      render: (data) => {
        return <TextXSMall text={`${data}`} />;
      },
    },
    {
      title: 'เลขที่',
      width: 50,
      dataIndex: 'docNo',
      align: 'center',
      render: (data) => {
        return <TextXSMall text={`${data}`} />;
      },
    },
    {
      title: 'รายการ',
      width: 200,
      dataIndex: 'model',
      align: 'center',
      render: (_, record) => {
        return {
          props: {
            style: { textAlign: 'left' },
          },
          children: <TextXSMall text={`${record.items[0].model}`} />,
        };
      },
    },
    {
      title: 'จำนวนเดือน',
      width: 70,
      dataIndex: 'monthTot',
      align: 'center',
      render: (data) => {
        return <TextXSMall text={`${data}`} />;
      },
    },
    {
      title: 'จำนวนดอกเบี้ย',
      width: 70,
      dataIndex: 'monthInt',
      align: 'center',
      render: (data) => {
        return <TextXSMall text={`${data}`} />;
      },
    },
    {
      title: 'ราคารวม',
      width: 70,
      dataIndex: 'summary',
      align: 'center',
      render: (data) => {
        return <TextLarge text={`${data}`} color={color['red']} />;
      },
    },
    {
      title: 'สถานะ',
      width: 70,
      dataIndex: 'billState',
      align: 'center',
      render: (data) => {
        return <TagComponent text={`${data}`} />;
      },
    },
    // {
    //   title: '',
    //   dataIndex: 'action',
    //   skipExport: true,
    //   width: 40,
    //   fixed: 'right',
    //   render: (_, record) => {
    //     return (
    //       <ContainerButton center>
    //         {record.paymentStatus === 'WAIT-CONFIRM' && (
    //           <Popconfirm
    //             title={'คุณต้องการลบรายการนี้ใช่หรือไม่'}
    //             onConfirm={() => onDelete([record.paymentLineId])}
    //             okText="ใช่"
    //             cancelText="ไม่ใช่"
    //           >
    //             <ButtonTheme useFor="REMOVE" />
    //           </Popconfirm>
    //         )}
    //       </ContainerButton>
    //     );
    //   },
    // },
  ];
};
