import { takeEvery } from 'redux-saga/effects';
import { login, loginGoogle, logout } from './api';
import * as type from './types';

function* authenSaga() {
  yield takeEvery(type.AUTHEN_LOGIN_REQ, login);
  yield takeEvery(type.AUTHEN_LOGOUT_REQ, logout);
  yield takeEvery(type.AUTHEN_LOGIN_GOOGLE_REQ, loginGoogle);
}

export default authenSaga;
