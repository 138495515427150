import { useEffect, useState } from 'preact/hooks';
import { openLoading, closeLoading } from '../../../components/alert/useAlert';

export const useFetchApi = () => {
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    onFetchGraphdata();
  }, []);

  const onFetchGraphdata = () => {
    openLoading();
    fetch('https://gw.alipayobjects.com/os/bmw-prod/360c3eae-0c73-46f0-a982-4746a6095010.json')
      .then((response) => response.json())
      .then((json) => setGraphData(json))
      .catch((error) => {
        console.log('fetch data failed', error);
      });
    closeLoading();
  };

  return {
    graphData,
  };
};
