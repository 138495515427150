import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Routes from './routes';
import { navigationRef } from './utils/navigation';
import GlobalStyle from './styles/global-style';
import * as ROUTES from './resources/routes-name';
import { Fragment } from 'react';
import { App as AppAntD } from 'antd';
import LoginScene from './screens/login';
import { Provider } from 'react-redux';
import store from './redux/store.js';
import Alert from './components/alert/alert';
import Loading from './components/loading/loading.jsx';
import { env } from './utils/env/config';

if (env.node_env !== 'development') {
  console.log = () => null;
  console.error = () => null;
}

const AppContainer = () => {
  if(window.location.pathname === '/') {
    window.location.replace(ROUTES.ROUTES_PATH.ROUTE_REPORT_INTST.PATH);
  }
  
  return (
    <Provider store={store}>
      <Loading>
        <Alert />
        <Router ref={navigationRef}>
          <div />
          <Switch>
            <Route exact path={ROUTES.ROUTE_LOGIN_MANUAL} component={LoginScene} />
            <Route exact component={Routes} />
          </Switch>
        </Router>
      </Loading>
    </Provider>
  );
};

const App = () => {
  return (
    <Fragment>
      <AppAntD>
        <AppContainer />
        <GlobalStyle />
      </AppAntD>
    </Fragment>
  );
};

export default App;
