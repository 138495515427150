import React from 'react';
import { color } from './../../../resources/color';
import { Icon } from './../../../resources/icon';

export const useFormState = () => {
  const defaultValues = {
    firstName: '',
    lastName: '',
    job: '',
    gender: '',
    file_upload: [],
    image_upload: [],
  };

  const cardItems = [
    {
      label: 'Delay task',
      icon: <Icon.alert style={{ fontSize: '24px', color: color.remove }} />,
      value: '1',
    },
    {
      label: 'Assign today',
      icon: <Icon.bookmark style={{ fontSize: '24px', color: color.edit }} />,
      value: '4',
    },
    {
      label: 'Done today',
      icon: <Icon.checkboxSquare style={{ fontSize: '24px', color: color.submit }} />,
      value: '0',
    },
  ];

  return {
    defaultValues,
    cardItems,
  };
};
