export const color = {
  theme: '#FFBF00',
  edit: '#ff7b54',
  orange: '#ff7b54',
  submit: '#FFBF00',
  clear: '#707070',
  remove: '#d35d6e',
  search: '#1890ff',
  red: '#FF3333',
  logout: '#737373',
  grey: '#e7e6e6',
  green: '#00FF88',
  yellow: '#F4D35E',
  fadered: '#ffeae6',
};
