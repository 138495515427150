import { Button, ConfigProvider } from 'antd';
import styled from 'styled-components';
import { color } from '../../resources/color';
import { Icon } from '../../resources/icon';

export const ButtonTheme = (props) => {
  return renderButtonType(props);
};

const renderButtonType = (props) => {
  let { useFor, title } = props;
  let stuff = {
    htmlType: props.htmlType,
    style: { ...props.style },
    ...props,
  };

  switch (useFor) {
    case 'SEARCH':
      title = 'ค้นหา';
      stuff = {
        ...stuff,
        bgcolor: color.search,
        icon: <Icon.search />,
        type: 'primary',
      };
      break;
    case 'EDIT':
      title = 'แก้ไข';
      stuff = {
        ...stuff,
        bgcolor: color.edit,
        icon: <Icon.edit />,
        type: 'primary',
      };
      break;
    case 'REMOVE':
      stuff = {
        ...stuff,
        bgcolor: color.remove,
        icon: <Icon.remove />,
        type: 'primary',
      };
      break;
    case 'VIEW':
      title = title || 'ดูข้อมูล';
      stuff = {
        ...stuff,
        icon: <Icon.view />,
        type: 'primary',
      };
      break;
    case 'CLEAR':
      title = 'ล้างข้อมูล';
      stuff = {
        ...stuff,
        icon: <Icon.clear />,
        type: 'default',
      };
      break;
    case 'DOWNLOAD':
      title = title || 'ดาว์นโหลด';
      stuff = {
        ...stuff,
        bgcolor: color.submit,
        icon: <Icon.download />,
        type: 'primary',
      };
      break;
    case 'PRINT':
      title = title || 'พิมพ์';
      stuff = {
        ...stuff,
        bgcolor: color.remove,
        icon: <Icon.print />,
        type: 'primary',
      };
      break;
    case 'SUBMIT':
      title = 'บันทึก';
      stuff = {
        ...stuff,
        bgcolor: color.submit,
        icon: <Icon.save />,
        type: 'primary',
      };
      break;
    case 'CONFIRM':
      title = title || 'ยืนยัน';
      stuff = {
        ...stuff,
        bgcolor: color.submit,
        icon: <Icon.confirm />,
        type: 'primary',
      };
      break;
    case 'CANCEL':
      title = 'ยกเลิก';
      stuff = {
        ...stuff,
        icon: <Icon.cancel />,
        type: 'default',
      };
      break;
    case 'LOGIN':
      title = 'เข้าสู่ระบบ';
      stuff = {
        ...stuff,
        icon: <Icon.login />,
        type: 'primary',
      };
      break;
    case 'LOGOUT':
      stuff = {
        ...stuff,
        bgcolor: color.logout,
        icon: <Icon.login />,
        type: 'primary',
      };
      break;
    case 'FORGET_PASSWORD':
      title = 'ลืมรหัสผ่าน';
      stuff = {
        ...stuff,
        icon: <Icon.forgetPassword />,
        type: 'default',
      };
      break;
    case 'BACK':
      title = 'ย้อนกลับ';
      stuff = {
        ...stuff,
        icon: <Icon.back />,
        type: 'default',
      };
      break;
    case 'SWITCH':
      stuff = {
        ...stuff,
        icon: <Icon.back />,
        type: 'primary',
      };
      break;
    case 'CREATE':
      stuff = {
        ...stuff,
        bgcolor: color.search,
        icon: <Icon.create />,
        type: 'primary',
      };
      break;
    case 'ZIP_XML':
      stuff = {
        ...stuff,
        icon: <Icon.download />,
        type: 'primary',
      };
      break;
    case 'RESEND':
      stuff = {
        ...stuff,
        icon: <Icon.reAct />,
        type: 'default',
      };
      break;
    case 'REQ_PASSWORD':
      stuff = {
        ...stuff,
        icon: <Icon.forgetPassword />,
        bgcolor: color.search,
        type: 'primary',
      };
      break;
    case 'RE_CHECK':
      stuff = {
        ...stuff,
        icon: <Icon.recheck />,
        bgcolor: color.edit,
        type: 'primary',
      };
      break;
    case 'REGISTER':
      stuff = {
        ...stuff,
        icon: <Icon.register />,
        bgcolor: color.submit,
        type: 'primary',
      };
      break;
    case 'HISTORY':
      stuff = {
        ...stuff,
        icon: <Icon.history />,
        bgcolor: color.clear,
        type: 'primary',
      };
      break;
    case 'IMPORT':
      title = title || 'นำข้อมูลเข้า';
      stuff = {
        ...stuff,
        bgcolor: color.clear,
        icon: <Icon.import />,
        type: 'primary',
      };
      break;
    default:
      stuff = {
        ...stuff,
        bgcolor: props.color,
      };
  }

  const { icon, ...rest } = stuff;
  return (
    useFor &&
    !props.hidden && (
      <ConfigProvider
        theme={
          rest.bgcolor && {
            token: {
              colorPrimary: rest.bgcolor,
            },
          }
        }
      >
        <ButtonStyle {...rest} size={rest.size || 'middle'}>
          {!rest.loading && (
            <div style={{ display: 'flex' }}>
              <div style={{ height: '100%', marginTop: '2px' }}>{icon}</div>
              <div>&nbsp;{title}</div>
            </div>
          )}
        </ButtonStyle>
      </ConfigProvider>
    )
  );
};

export const ButtonStyle = styled(Button)`
  align-items: center;
  margin: 5px;
`;
