import styled from 'styled-components';
import { Typography } from 'antd';
const { Text } = Typography;

/*

  TextLarge  18px --> header
  TextMedium 16px--> title card
  TextSmall 14px --> menu, detail
  TextXSMall 12px --> ข้อความ 'โปรดระบุ'
*/

export const TextInputLabel = ({ color, bold, text, align, ...props }) => {
  return (
    <div>
      <TextStyle style={{ color }} bold={true} size={'11px'} align={align} {...props}>
        {text}
      </TextStyle>
    </div>
  );
};

export const TextXSMall = ({ color, bold, text, align, ...props }) => {
  if (text === 'undefined') {
    text = '';
  }
  return (
    <div>
      <TextStyle style={{ color }} bold={bold} size={'12px'} align={align} {...props}>
        {text}
      </TextStyle>
    </div>
  );
};

export const TextSmall = ({ color, bold, text, align, onClick, children, ...props }) => {
  return (
    <div>
      <TextStyle
        style={{ color }}
        bold={bold}
        size={'14px'}
        align={align}
        onClick={onClick}
        {...props}
      >
        {text || children}
      </TextStyle>
    </div>
  );
};

export const TextLarge = ({ color, bold, text, align, ...props }) => {
  const style = {
    color,
    fontFamily: bold ? 'SarabunBold' : 'Sarabun',
  };

  return (
    <div>
      <TextStyle style={style} size={'16px'} align={align} {...props}>
        {text}
      </TextStyle>
    </div>
  );
};

export const TextLogoLogin = ({ color, bold, text, align, ...props }) => {
  return (
    <div>
      <TextStyle style={{ color }} bold={bold} size={'30px'} align={align} {...props}>
        {text}
      </TextStyle>
    </div>
  );
};

export const TextCustom = ({ color, bold, text, align, size, ...props }) => {
  return (
    <div>
      <TextStyle style={{ color }} bold={bold} size={size} align={align} {...props}>
        {text}
      </TextStyle>
    </div>
  );
};

const TextStyle = styled(Text)`
  font-size: ${(props) => (props.size ? props.size : '12px')};
  text-align: ${(props) => (props.align ? props.align : '')};
  margin: 5px;
`;
