import Cookies from 'universal-cookie';

const config = {
  path: '/',
  expires: getExpire(),
};

const cookies = new Cookies();

function getExpire() {
  return new Date(new Date().getTime() + 60 * 60 * 24 * 1000 * 15);
}

export const setCookies = (name, value) => {
  cookies.set(name, value, { ...config });
};

export const getCookies = (name) => {
  return cookies.get(name);
};

export const removeCookies = (name, configCookies) => {
  return cookies.remove(name, configCookies);
};
