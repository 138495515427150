import { useEffect, useState } from 'preact/hooks';
import { GET_LIST_CUSTOMER, POST, SWITCH_STATUS_APP } from '../../../services';
import { Notification } from '../../../components/notification';

const useGetCustomerList = () => {
  const [dataCustomer, setDataCustomer] = useState([]);
  const [isLoadingTable, setISLoadingTable] = useState(true);

  useEffect(() => {
    onFetchCustomerList({ customerCode: '' });
  }, []);

  const onFetchCustomerList = async (o = {}) => {
    try {
      const query = await POST(GET_LIST_CUSTOMER, o);
      const { result } = query;
      if (!result) {
        return;
      }
      const { customer } = result;
      const data = customer.map((e, idx) => {
        return {
          ...e,
          indx: idx,
        };
      });
      setDataCustomer(data);
      setISLoadingTable(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onSearch = async (data) => {
    const { customer } = data;
    await onFetchCustomerList({
      customerCode: customer || '',
    });
  };

  const onSwitchApp = async (status, record) => {
    const { customerCode, indx } = record;
    await POST(SWITCH_STATUS_APP, { customerCode, status: `${+status}` });
    dataCustomer[indx].statusApp = `${+status}` === '1' ? 'ST-011' : 'ST-299';
    setDataCustomer([...dataCustomer]);
    Notification({ type: 'success', message: 'ทำรายการสำเร็จ' });
  };

  return {
    dataCustomer,
    isLoadingTable,
    onSearch,
    onSwitchApp,
  };
};

export default useGetCustomerList;
