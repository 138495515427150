import { color } from '../resources';

export const getPixel = (value, fullWidth = false) => {
  if (fullWidth) {
    return '100%';
  }
  if (typeof value === 'number') {
    return `${value * 4}px`;
  }
  return value;
};

export const getColor = (colors, key) => {
  return color[key] ?? key;
};
