
import React from "react";
import { SearchLayout } from "./SearchLayout";
import { TableLayout } from './TableLayout'
export const PageLayout = ({ searchLayout, tableLayout, content, action }) => {
	return (
		<>
			{
				searchLayout && <SearchLayout props={{ ...searchLayout }} />
			}
			{
				content
			}
			{
				tableLayout && <TableLayout props={{ ...tableLayout }} />
			}
			{
				action
			}

		</>
	)
}
