import { useMemo } from 'preact/hooks';
import { TextXSMall } from '../text';
import { STATUS } from './constant/status';
import { color } from '../../resources';

export const TagComponent = ({ text }) => {
  const data = useMemo(() => {
    return STATUS[text] || { label: text, color: color['theme'] };
  }, [text]);

  const divStyle = {
    backgroundColor: data.color,
    padding: '2px',
    paddingRight: '6px',
    paddingLeft: '6px',
    borderRadius: '100px',
  };

  return (
    <div style={divStyle}>
      <TextXSMall text={data.label} />
    </div>
  );
};
