import { useEffect, useState } from 'preact/hooks';
import { GET_REPORT_INTST, POST } from '../../../services';
import moment from 'moment';

const useGetReportIntst = () => {
  const [dataIntst, setDataIntst] = useState([]);
  const [totalDataIntst, setTotalDataIntst] = useState(0);
  const [isLoadingTable, setISLoadingTable] = useState(true);

  useEffect(() => {
    onFetchReportIntst({
      startDate: moment().format(),
      endDate: moment().format(),
    });
  }, []);

  const onFetchReportIntst = async (o = {}) => {
    try {
      const query = await POST(GET_REPORT_INTST, o);
      const { result } = query;
      if (!result) {
        return;
      }
      const { list } = result;
      const data = list.map((e, idx) => {
        return {
          ...e,
          indx: idx,
        };
      });
      setDataIntst(data);
      setTotalDataIntst(data.length);
      setISLoadingTable(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onSearch = async (data) => {
    const { customer, date = [] } = data;
    await onFetchReportIntst({
      customer,
      startDate: date[0] ? moment(date[0], 'DD/MM/YYYY').format() : null,
      endDate: date[1] ? moment(date[1], 'DD/MM/YYYY').format() : null,
    });
  };

  return {
    dataIntst,
    totalDataIntst,
    isLoadingTable,
    onSearch,
  };
};

export default useGetReportIntst;
