import { Row, Col, Layout } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import { color } from '../resources';
const { Header, Sider } = Layout;

const SarabunLight =
  'https://fonts.gstatic.com/s/sarabun/v13/DtVmJx26TKEr37c9YL5rilUs6yLUrwB0lw.woff2'; // 400
const Sarabun = 'https://fonts.gstatic.com/s/sarabun/v13/DtVjJx26TKEr37c9aBVJn3YO5gg.woff2';
const SarabunMedium =
  'https://fonts.gstatic.com/s/sarabun/v13/DtVmJx26TKEr37c9YOZqilss6yLUrwA.woff2';
const SarabunBold = 'https://fonts.gstatic.com/s/sarabun/v13/DtVmJx26TKEr37c9YMptilss6yLUrwA.woff2';

const GlobalStyle = createGlobalStyle`
    @font-face {
      font-family: 'SarabunLight';
      font-display: swap;
      src: url(${SarabunLight}) format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    @font-face {
      font-family: 'Sarabun';
      font-display: swap;
      src: url(${Sarabun}) format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    @font-face {
      font-family: 'SarabunMedium';
      font-display: swap;
      src: url(${SarabunMedium}) format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    @font-face {
      font-family: 'SarabunBold';
      font-display: swap;
      src: url(${SarabunBold}) format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    html, body {
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
        background: #FAFAFA;
        font-family: 'Sarabun', 'sans-serif' !important;
        color: #444;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
`;

export const RowComponent = styled(Row)`
  display: flex;
  align-items: center;
`;

export const Required = styled.div`
  color: ${color.red};
  position: relative;
  top: 3px;
  left: 3px;
`;

export const ContainerButton = styled(Col)`
  display: flex;
  justify-content: ${(props) => (props.center ? 'center' : 'flex-end')};
  align-item: ${(props) => (props.center ? 'center' : 'flex-end')};
  width: 100%;
`;

export const HeaderBarStyle = styled(Header)`
  padding: 0 !important;
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
`;

export const SideBarStyle = styled(Sider)`
  overflow: auto;
  height: 100vh;
  position: fixed !important;
  left: 0;
  top: 0;
  bottom: 0;
`;

export default GlobalStyle;
