import { create } from 'apisauce';
import { getQueryString } from '../functions';
import { getCookies } from '../store/useCookies';
import { env } from '../utils/env/config';
export const ip = 'http://192.168.1.169:2000';

export const MODE_API = {
  DEVELOPMENT: env.url_dev,
  UAT: env.url_uat,
  PRODUCTION: env.url_prd,
};
export const CONNECT_API = MODE_API[`${env.node_env}`.toUpperCase()];
const api = create({
  baseURL: CONNECT_API,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 10000, // 10 seconds
});

const apiupload = create({
  baseURL: CONNECT_API,
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});
/**
 * ฟังก์ชั่นสำหรับ ยิงข้อมูล
 * @param {string} path
 * @param {{}} obj
 * @return {{}}
 */
export const POST = (path, obj, token = getCookies('accessToken')) =>
  new Promise((resolve, reject) => {
    api
      .post(path, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

/**
 * ฟังก์ชั่นสำหรับ ดึงข้อมูล
 * @param {string} path
 *
 */
export const GET = (path, token = getCookies('accessToken')) =>
  new Promise((resolve, reject) => {
    api
      .get(
        path,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

export const UPLOAD = (path, formdata, token = getCookies('accessToken')) =>
  new Promise((resolve, reject) => {
    apiupload
      .post(path, formdata, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

/* ################################################## URL ################################################## */
export const LOGIN = `/authen/admin/login`;
export const LOGIN_GOOGLE = `/authen/login-google`;
export const REGISTER = `/authen/register`;
export const GET_PROFILE = `/authen/get_profile`;
export const GET_ALL_PRODUCT = `/product/get-all`;
export const GET_PRODUCT_SEARCH = (obj) => `/product/search?${getQueryString(obj)}`;

export const GET_REPORT_INTST = '/report/intst';

export const GET_LIST_CUSTOMER = '/customer/list';
export const SWITCH_STATUS_APP = '/customer/app/status';
