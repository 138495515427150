export const AUTHEN_LOGIN_REQ = '@AUTHEN/LOGIN_REQ';
export const AUTHEN_LOGIN_SUCCESS = '@AUTHEN/LOGIN_SUCCESS';
export const AUTHEN_LOGIN_FAIL = '@AUTHEN/LOGIN_FAIL';

export const AUTHEN_LOGOUT_REQ = '@AUTHEN/LOGOUT_REQ';
export const AUTHEN_LOGOUT_SUCCESS = '@AUTHEN/LOGOUT_SUCCESS';
export const AUTHEN_LOGOUT_FAIL = '@AUTHEN/LOGOUT_FAIL';

export const AUTHEN_LOGIN_GOOGLE_REQ = '@AUTHEN/LOGIN_GOOGLE_REQ';
export const AUTHEN_LOGIN_GOOGLE_SUCCESS = '@AUTHEN/LOGIN_GOOGLE_SUCCESS';
export const AUTHEN_LOGIN_GOOGLE_FAIL = '@AUTHEN/LOGIN_GOOGLE_FAIL';
