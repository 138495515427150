import './style/table.css';
import { StyledContainer } from '../../components/container/styled';
import { PageLayout } from '../../components/page-layout/PageLayout';
import { Icon } from '../../resources';
import { COLUMNS } from './constant/table';
import { FORM_SEARCH_TABLE } from './form/search.table';
import useGetCustomerList from './hook/useGetCustomerList';

const CustomerScene = () => {
  const { dataCustomer, onSearch, isLoadingTable, onSwitchApp } = useGetCustomerList();

  async function onSwitch(status, record) {
    await onSwitchApp(status, record);
  }

  return (
    <StyledContainer>
      <PageLayout
        searchLayout={{
          title: 'จัดการข้อมูลลูกค้าในการใช้งานแอปพลิเคชั่น',
          formSearch: FORM_SEARCH_TABLE(),
          icon: <Icon.todo />,
          onSearch: (data) => onSearch(data),
        }}
        tableLayout={{
          bordered: true,
          loading: isLoadingTable,
          columns: COLUMNS({ onSwitch }),
          rowKey: 'indx',
          dataSource: dataCustomer,
          total: dataCustomer.length,
          hasPagination: true,
          scroll: { x: 'max-content' },
          tableStyle: { marginTop: '5px' },
        }}
      />
    </StyledContainer>
  );
};

export default CustomerScene;
