import React from "react";
import { CardTheme } from "../card/index";
import TableComponent from './../table/index.jsx';


export const TableLayout = ({ props }) => {
	const { style, columns, dataSource, rowSelection, extraContent, hasPagination, rowKey, components, ...rest } = props
  return (
		<CardTheme
			style={{ marginTop: '4%', ...style }}
			content={
				<>
				{extraContent}
				<TableComponent
          rowKey={rowKey}
					columns={columns}
					dataSource={dataSource}
          hasPagination={hasPagination || false}
					rowSelection={rowSelection}
          components={components}
          {...rest}
				/>
				</>
			}
		/>
	)
}