import { put } from 'redux-saga/effects';
import { POST } from '../../services';
import { LOGIN, LOGIN_GOOGLE } from './../../services/index';
import * as type from './types';
import { closeLoading, openAlert, openLoading } from '../../components/alert/useAlert';
import { navigateTo } from '../../utils/navigation';
import { ROUTES_PATH, ROUTE_LOGIN_MANUAL } from '../../resources/routes-name';
import { setCookies, removeCookies } from './../../store/useCookies';

export function* login(payload) {
  try {
    openLoading();
    const users = yield POST(LOGIN, payload.payload);
    yield put({ type: type.AUTHEN_LOGIN_SUCCESS, users });
    setCookies('accessToken', users.result.accessToken);
    navigateTo({ pathname: ROUTES_PATH.ROUTE_REPORT_INTST.PATH });
  } catch (e) {
    openAlert({ type: 'error', message: e.message });
    yield put({ type: type.AUTHEN_LOGIN_FAIL, message: e.message });
  } finally {
    closeLoading();
  }
}

export function* logout() {
  try {
    removeCookies('accessToken', { path: '/' });
    yield put({ type: type.AUTHEN_LOGOUT_SUCCESS });
  } catch (e) {
    openAlert({ type: 'error', message: e.message });
    yield put({ type: type.AUTHEN_LOGOUT_FAIL, message: e.message });
  } finally {
    navigateTo({ pathname: ROUTE_LOGIN_MANUAL });
  }
}

export function* loginGoogle(payload) {
  try {
    openLoading();
    const result = yield POST(LOGIN_GOOGLE, payload.payload);
    yield put({ type: type.AUTHEN_LOGIN_GOOGLE_SUCCESS });
    setCookies('accessToken', result.result.accessToken);
    navigateTo({ pathname: ROUTES_PATH.ROUTE_REPORT_INTST.PATH });
  } catch (e) {
    openAlert({ type: 'error', message: e.message });
    yield put({ type: type.AUTHEN_LOGIN_GOOGLE_FAIL, message: e.message });
  } finally {
    closeLoading();
  }
}
