import { color } from '../../../resources';

export const STATUS = {
  'WAIT-CONFIRM': {
    label: 'รออนุมัติ',
    color: color['yellow'],
  },
  CONFIRMED: {
    label: 'อนุมัติแล้ว',
    color: color['green'],
  },
  DELETED: {
    label: 'ยกเลิก',
    color: color['clear'],
  },
};
