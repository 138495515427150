import { Col, Row, Checkbox, Tag, Segmented } from 'antd';
import { StyledContainer } from '../../components/container/styled';
import WrapperComponent from '../../components/hoc/Wrapper-component';
import { Area } from '@ant-design/plots';
import { TextSmall } from '../../components/text';
import { TextCustom } from './../../components/text/index';
import { CardTheme } from './../../components/card/index';
import { color } from './../../resources/color';
import { useFetchApi } from './hook/useFetchApi';
import { useFormState } from './hook/useFormState';

const HomeScene = () => {
  const { graphData } = useFetchApi();
  const { cardItems } = useFormState();

  const config = {
    data: graphData,
    xField: 'timePeriod',
    yField: 'value',
    xAxis: {
      range: [0, 1],
    },
  };

  return (
    <StyledContainer>
      <Row gutter={[8, 8]}>
        {cardItems.map((el, i) =>
          <Col key={`${el.label}_${i + 1}`} xs={{ span: 23, offset: 1, }} sm={{ span: 23, offset: 1, }} lg={{ span: 7, offset: 1, }}>
            <CardTheme style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}>
              <Row>
                <Col xs={{ span: 12, }} sm={{ span: 12 }} lg={{ span: 12 }}>
                  <TextSmall text={el.label} />
                </Col>
                <Col xs={{ span: 12 }} sm={{ span: 12 }} lg={{ span: 12 }} style={{ textAlignLast: 'right' }}>
                  {el.icon}
                </Col>
              </Row>
              <Row>
                <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ display: 'flex', justifyContent: 'center' }}>
                  <TextCustom text={el.value} size="30px" bold />
                </Col>
              </Row>
            </CardTheme>
          </Col>
        )}
      </Row>
      <Row gutter={[8, 8]}>
        <Col xs={{ span: 23, offset: 1, }} sm={{ span: 23, offset: 1, }} lg={{ span: 23, offset: 1, }}>
          <CardTheme title="Task today" style={{ marginTop: 20, boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}>
            <Row style={{ marginTop: 10 }}>
              <Col xs={{ span: 12, }} sm={{ span: 12 }} lg={{ span: 12 }}>
                <Checkbox>
                  Create add stock UI
                </Checkbox>
              </Col>
              <Col xs={{ span: 12 }} sm={{ span: 12 }} lg={{ span: 12 }} style={{ textAlignLast: 'right' }}>
                <Tag color="purple">stock web</Tag>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col xs={{ span: 12, }} sm={{ span: 12 }} lg={{ span: 12 }}>
                <Checkbox>
                  fix bug table
                </Checkbox>
              </Col>
              <Col xs={{ span: 12 }} sm={{ span: 12 }} lg={{ span: 12 }} style={{ textAlignLast: 'right' }}>
                <Tag color="purple">stock web</Tag>
              </Col>
            </Row>
          </CardTheme>
        </Col>
      </Row>
      <Row gutter={[8, 8]} style={{ marginTop: 20 }}>
        <Col xs={{ span: 23, offset: 1, }} sm={{ span: 23, offset: 1, }} lg={{ span: 23, offset: 1, }}>
          <Segmented block options={['จำนวนงานที่ทำเสร็จแบ่งตามเดือน', 'จำนวนงานทุกสถานะแบ่งตามเดือน']} />
          <div style={{ marginTop: 10 }}>
            <Area {...config} />
          </div>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default WrapperComponent('HomeScene')(HomeScene);
